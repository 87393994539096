import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import theme from "../../src/constants/theme"
import Navbar from "../../src/components/Navbar"
import Footer from "../../src/components/Footer"
import { StyledEngineProvider } from "@mui/material/styles"
import "../../src/assets/layout.css" // For importing the Windsor font

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <meta name="google-site-verification" content="KpBPRD3kzQCZf8pz7uetl9YAQN4A1BYverO8IRQAwFQ" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500&display=swap"
          rel="stylesheet"
        />
        <link href="https://fonts.googleapis.com/css2?family=Inter&family=Roboto&display=swap" rel="stylesheet" />
      </Helmet>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={createTheme(theme)}>
          <CssBaseline />
          <Navbar />
          <main>{props.children}</main>
          <Footer />
        </ThemeProvider>
      </StyledEngineProvider>
    </React.Fragment>
  )
}

TopLayout.propTypes = {
  children: PropTypes.node,
}
